<template>
  <v-row class="mt-2">
    <el-tabs
      v-model="tab"
      type="card"
      v-if="!disabled"
      style="width: 100%; height: 100%"
      @tab-click="tabClicked"
    >
      <el-tab-pane key="1" label="Rich Text Editor" name="1">
        <wysiwyg v-model="content" @change="onEditorChange" />
      </el-tab-pane>
      <el-tab-pane key="2" label="HTML Code" name="2">
        <el-input
          size="mini"
          style="width: 100%; height: 100%"
          type="textarea"
          v-model="code"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="html ql-editor" v-if="disabled" v-html="value"></div>
  </v-row>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import Vue from "vue";
import { Tabs, TabPane } from "element-ui";
Vue.use(Tabs);
Vue.use(TabPane);

import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {});

export default {
  created() {
    this.content = this.value;
  },
  watch: {
    value: function () {
      this.content = this.value;
    },
  },
  props: {
    value: null,
    html: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    requireInput: {
      type: Boolean,
      default: false,
    },
    inputHint: {
      type: String,
      default: "Enter Details",
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      content: null,
      code: null,
      loading: false,
      showForm: false,
      tab: "1",
      editorOption: {},
    };
  },
  methods: {
    /*onEditorBlur(quill) {
      },
      onEditorFocus(quill) {
      },
      onEditorReady(quill) {
      },*/
    onEditorChange() {
      //console.log(html);
      //console.log(this.value);
      //console.log(this.content);
      //this.content = html;
      //this.content = this.value;
      this.$emit("input", this.content);
    },
    tabClicked(tab) {
      if (tab.index == "0") {
        this.content = this.code;
        this.$emit("input", this.content);
      } else if (tab.index == "1") {
        this.content = this.value;
        if (this.empty(this.content)) {
          this.code = "";
          return;
        }
        this.code = this.content.replace(
          "<p>",
          // eslint-disable-next-line no-unused-vars
          function (str, p1, offset, s) {
            return p1 + "\n" + str;
          }
        );
        this.code = this.content.replace(
          "</p>",
          // eslint-disable-next-line no-unused-vars
          function (str, p1, offset, s) {
            return p1 + "\n" + str;
          }
        );
        this.code = this.content.replace(
          "<div>",
          // eslint-disable-next-line no-unused-vars
          function (str, p1, offset, s) {
            return p1 + "\n" + str;
          }
        );
        this.code = this.content.replace(
          "</div>",
          // eslint-disable-next-line no-unused-vars
          function (str, p1, offset, s) {
            return p1 + "\n" + str;
          }
        );
        this.code = this.content.replace(
          "<br>",
          // eslint-disable-next-line no-unused-vars
          function (str, p1, offset, s) {
            return p1 + "\n" + str;
          }
        );
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  name: "HTMLEditor",
};
</script>
<style scoped>
.v-dialog {
  overflow-y: none !important;
}
</style>
